@font-face {
  font-family: "CormorantLight";
  src: url("./styles/Cormorant-Light.ttf") format("ttf");
  font-weight: 100;
}
* {
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family:'Helvetica Neue';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #E2DDD2;
  overflow-x: hidden;
}

/* FONT */
h1, h2, h4, h5, h6, a {
  color: #313131

}
h1 {
  font-size: min(1.5rem, 14px);
  font-weight: 400;
  margin: 10px 0;
}
h2 {
  font-family: "CormorantLight";
  font-size: 4rem;
  font-weight: 100;
}
h3 {
  color: #000;
  font-size: clamp(1rem, 2vw, 1.7rem);
  font-weight: bold;
  margin: 10px 0;

}
h6 {
  font-family: "CormorantLight";
  font-size: 1.2rem;
  font-weight: 100;
}
p{
  font-size: clamp(1.2rem, 1vw, 1.7rem);
  font-weight: 400;
  margin: 10px 0px;

}
a {
  text-decoration: none;
}
.font__button {
  font-size: clamp(4rem, 4vw, 7rem);
  font-weight: bold;
}
.uppercase {
  text-transform: uppercase;
}
.font__big {
  font-size: clamp(1rem, 12vw, 13rem);

  font-weight: bold;
}
.font__small {
  font-size: 0.9rem;
  font-weight: 400;
}


/* Positioning */
.inlineflex {
  display: inline-flex;
}
